<template>
  <div>
    <Form
      title="Editar 11 ideal"
      ref="elevenForm"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      form: {},
      loading: true,
      visible: false,
      completedForms: 0,
      updatedPlayers: 0
    }
  },
  methods: {
    toggleVisibility(visible) {
      this.visible = visible
    },
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: "/eleven",
          module: "eleven",
          id: this.$route.params.id3
        })
        .then((response) => {
          console.log("WINNING ELEVEN", response.data)
          this.form = {
            id: response.data.id,
            name: response.data.name,
            id_tournament: response.data.id_tournament,
            id_category: response.data.id_category,
            id_subcategory: response.data.id_subcategory,
            players: [
              {
                id_academy: response.data.players[0].player.id_academy,
                id_player: response.data.players[0].player.id,
                id_player_eleven: response.data.players[0].id
              },
              {
                id_academy: response.data.players[1].player.id_academy,
                id_player: response.data.players[1].player.id,
                id_player_eleven: response.data.players[1].id
              },
              {
                id_academy: response.data.players[2].player.id_academy,
                id_player: response.data.players[2].player.id,
                id_player_eleven: response.data.players[2].id
              },
              {
                id_academy: response.data.players[3].player.id_academy,
                id_player: response.data.players[3].player.id,
                id_player_eleven: response.data.players[3].id
              },
              {
                id_academy: response.data.players[4].player.id_academy,
                id_player: response.data.players[4].player.id,
                id_player_eleven: response.data.players[4].id
              },
              {
                id_academy: response.data.players[5].player.id_academy,
                id_player: response.data.players[5].player.id,
                id_player_eleven: response.data.players[5].id
              },
              {
                id_academy: response.data.players[6].player.id_academy,
                id_player: response.data.players[6].player.id,
                id_player_eleven: response.data.players[6].id
              },
              {
                id_academy: response.data.players[7].player.id_academy,
                id_player: response.data.players[7].player.id,
                id_player_eleven: response.data.players[7].id
              },
              {
                id_academy: response.data.players[8].player.id_academy,
                id_player: response.data.players[8].player.id,
                id_player_eleven: response.data.players[8].id
              },
              {
                id_academy: response.data.players[9].player.id_academy,
                id_player: response.data.players[9].player.id,
                id_player_eleven: response.data.players[9].id
              },
              {
                id_academy: response.data.players[10].player.id_academy,
                id_player: response.data.players[10].player.id,
                id_player_eleven: response.data.players[10].id
              }
            ],
            tournament: response.data.tournament,
            category: response.data.category,
            subcategory: response.data.subcategory
          }
          this.$refs.elevenForm.form = this.form
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getTournamentCategory() {
      this.$store
        .dispatch("global/getItem", {
          route: "/tournament_category",
          module: "tournamentCategory",
          id: this.$route.params.id2
        })
        .then((response) => {
          this.getAcademies()
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getAcademies() {
      this.$store
        .dispatch("global/getItems", {
          module: "tournamentAcademy",
          route: "/tournament_academy/all",
          noPaginate: true,
          isMaster: false,
          params: {
            active: 1,
            order_key: "id",
            order_value: "asc",
            verified: 1,
            id_tournament: this.form.id_tournament,
            id_category: this.form.id_category,
            id_subcategory: this.form.id_subcategory
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    save() {
      let form = this.$refs.elevenForm.form
      this.loading = true

      this.$store
        .dispatch("global/update", {
          payload: {
            id: this.form.id,
            name: form.name
          },
          route: "/eleven/update",
          module: "eleven"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master", response.code, this.$snotify)
          })
          this.loading = false

          if ([200, 201].includes(response.code)) {
            this.completedForms++
          }
          this.updatePlayers()
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    updatePlayers() {
      const players = this.$refs.elevenForm.form.players
      for (let element of players) {
        this.$store
          .dispatch("global/update", {
            payload: {
              id: element.id_player_eleven,
              id_player: element.id_player
            },
            route: "/player_eleven/update"
          })
          .then((response) => {
            this.loading = false
            if ([200, 201].includes(response.code)) {
              this.updatedPlayers++
            }
          })
          .catch((error) => {
            this.loading = false
            notify().then(({ notification }) => {
              notification("error", error.code, this.$snotify)
            })
          })
      }
    }
  },
  created() {
    this.getTournamentCategory()
    this.getItem()
  },
  watch: {
    completedForms() {
      if (this.completedForms === 2) {
        this.$router.push(
          "/torneos/" +
            this.$route.params.id +
            "/categorias/" +
            this.$route.params.id2 +
            "/academias"
        )
      }
    },
    updatedPlayers() {
      if (this.$refs.elevenForm.form.players.length === this.updatedPlayers) {
        this.completedForms++
      }
    }
  }
}
</script>
